import React, { createRef, useEffect, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { InputText, InputTextRef } from "@jobber/components/InputText";
import { CirclePicker, RGBColor } from "react-color";
import { gql, useMutation } from "@apollo/client";
import styles from "./AddColumnModal.css";
import { useAtlantisColors, useEncrypt } from "../../../hooks";

const ADD_COLUMN = gql`
  mutation AddColumn($retroID: ID!, $data: RetroUpdateInput!) {
    updateRetro(data: $data, where: { id: $retroID }) {
      id
      columns {
        title
      }
    }
  }
`;

interface AddColumnModalProps {
  open: boolean;
  close(): void;
  retroID: string;
}

interface Color {
  name: string;
  title: string;
  color: string;
}

export function AddColumnModal({ open, close, retroID }: AddColumnModalProps) {
  const titleRef = createRef<InputTextRef>();
  const [title, setTitle] = useState<string | number>("");
  const [color, setColor] = useState<string>("#556ACB");
  const [colorName, setColorName] = useState<string>("indigo");
  const [chooseColor, setChooseColor] = useState<boolean>(false);
  const atlantisColors = useAtlantisColors() as Color[];
  const [addColumn] = useMutation(ADD_COLUMN);
  const [encryptedTitle, encryptedColor] = useEncrypt([
    title.toString(),
    colorName,
  ]);

  useEffect(() => {
    if (open) {
      setTitle("");
      titleRef.current?.focus();
    }
  }, [open]);

  return (
    <Modal
      title="Add a column"
      open={open}
      onRequestClose={close}
      primaryAction={{ label: "Add Column", onClick: handleSave }}
    >
      <Content>
        <InputText
          placeholder="Column title"
          onEnter={handleSave}
          value={title}
          onChange={setTitle}
          ref={titleRef}
        />
        <div className={styles.color}>
          <Button
            label={chooseColor ? "Close" : "Choose Color"}
            type="tertiary"
            onClick={() => setChooseColor(!chooseColor)}
          />
          <div
            className={styles.example}
            style={{ background: color }}
            onClick={() => setChooseColor(!chooseColor)}
          />

          {chooseColor && (
            <div className={styles.picker}>
              <CirclePicker
                color={color}
                onChange={({ rgb, hex }) => {
                  const newColor = getColor(rgb);
                  setColor(hex);
                  setChooseColor(false);
                  if (newColor) {
                    setColorName(newColor.name);
                  }
                }}
                colors={atlantisColors.map(item => item.color)}
              />
            </div>
          )}
        </div>
      </Content>
    </Modal>
  );

  function getColor(rgb: RGBColor) {
    const rgbColor = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    return atlantisColors.find(item => item.color === rgbColor);
  }

  function handleSave() {
    addColumn({
      variables: {
        retroID,
        data: {
          columns: { create: { title: encryptedTitle, color: encryptedColor } },
        },
      },
    });
    close();
  }
}
