import { useContext } from "react";
import { StateContext } from "../Board/StateContext";
import { decrypt } from "../utilities";

export function useDecrypt(messages: string[], secret?: string) {
  secret = secret ?? useContext(StateContext).secret;
  if (!secret) {
    console.warn("Insecure!");
    return messages;
  }

  return messages.map(message => decrypt(message, secret));
}
