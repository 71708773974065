import { urlForRetro } from "./urlForRetro";

export function getFullUrl(id: string, secret: string) {
  const { protocol, hostname, port } = location;
  let prefix = `${protocol}//${hostname}`;
  if (port) {
    prefix = `${prefix}:${port}`;
  }

  return prefix + urlForRetro(id, secret);
}
