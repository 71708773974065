import React from "react";

export const StateContext = React.createContext<{
  boardID: string;
  showItems: boolean;
  sortItems: boolean;
  showActionItems: boolean;
  secret: string | undefined;
}>({
  boardID: "",
  showItems: false,
  sortItems: false,
  showActionItems: false,
  secret: undefined,
});
