import React, { useContext } from "react";
import { Text } from "@jobber/components/Text";
import { Markdown } from "@jobber/components/Markdown";
import emojiRegex from "emoji-regex";
import styles from "./RedactedText.css";
import { StateContext } from "../../../../StateContext";
import { useAuthorID } from "../../../../../hooks";

interface RedactedTextPros {
  text: string;
  itemAuthor?: string;
}

export function RedactedText({ text, itemAuthor }: RedactedTextPros) {
  const [authorID] = useAuthorID();
  const state = useContext(StateContext);

  if (state.showItems || itemAuthor === authorID) {
    return <Markdown content={text} />;
  } else {
    return (
      <Text>
        <span className={styles.redacted}>
          {text.replace(/[^ a-zA-Z0-9,.?!:()]/g, "i")}
        </span>
      </Text>
    );
  }
}
