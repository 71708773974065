import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { InputText } from "@jobber/components/InputText";
import { Content } from "@jobber/components/Content";
import styles from "./Root.css";
import { encrypt, urlForRetro } from "../utilities";

const CREATE_RETRO = gql`
  mutation CreateRetro($data: RetroCreateInput!) {
    createRetro(data: $data) {
      id
      title
    }
  }
`;

export function Root() {
  const history = useHistory();
  const [createRetro] = useMutation(CREATE_RETRO);
  const [boardName, setBoardName] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.page}>
      <div className={styles.card}>
        <Content>
          <Heading level={1}>Frend-tro</Heading>
          <Card title="Create a new board">
            <Content>
              <InputText
                value={boardName}
                onChange={handleNameChange}
                placeholder="Board name"
              />
              <Button
                loading={loading}
                label="Create Board"
                onClick={handleNewBoard}
                disabled={boardName === ""}
              />
            </Content>
          </Card>
          <Button
            label="View a demo retro"
            type="tertiary"
            size="small"
            to={urlForRetro(
              "ckf8lgbbb1kbg0a26k266jp7d",
              "07a60a67-502f-435c-b5b6-1380c0ad9a02",
            )}
          />
        </Content>
      </div>
    </div>
  );

  function handleNameChange(val: string) {
    setBoardName(val);
  }

  async function handleNewBoard() {
    setLoading(true);
    const secret = uuid();
    const result = await createRetro({
      variables: {
        data: {
          title: encrypt(boardName, secret),
        },
      },
    });
    history.push(urlForRetro(result.data.createRetro.id, secret));
  }
}
