export async function copyStringToClipboard(string: string) {
  const el = document.createElement("textarea");
  el.value = string;
  el.style.position = "absolute";
  el.style.left = "-9999em";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}
