import { v1 as uuid } from "uuid";
import { useLocalStorage } from "use-hooks";
import { useEffect } from "react";

export function useAuthorID() {
  const [userID, setUserID] = useLocalStorage("userID", "");

  useEffect(() => {
    if (userID === "") refreshUserID();
  }, [userID]);

  return [userID, refreshUserID];

  function refreshUserID() {
    setUserID(uuid());
  }
}
