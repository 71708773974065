import { useContext } from "react";
import { StateContext } from "../Board/StateContext";
import { encrypt } from "../utilities";

export function useEncrypt(messages: string[]) {
  const { secret } = useContext(StateContext);
  if (!secret) {
    console.warn("Insecure!");
    return messages;
  }

  return messages.map(message => encrypt(message, secret));
}
