import React, { useContext, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { InputText } from "@jobber/components/InputText";
import styles from "./ListItem.css";
import { RedactedText } from "../RedactedText";
import {
  useAuthorID,
  useBoardID,
  useDecrypt,
  useEncrypt,
} from "../../../../../hooks";
import { countVotes } from "../../../../../utilities";
import { StateContext } from "../../../../StateContext";

export interface ListItem {
  id: string;
  text: string;
  authorID?: string;
  votes: { id: string; voterID: string; count: number }[];
}

interface ListItemProps {
  item: ListItem;
  votable?: boolean;
}

const ADD_VOTE = gql`
  mutation AddVote($boardID: ID!, $itemID: ID!, $voterID: String!) {
    updateItem(
      data: { votes: { create: { voterID: $voterID, count: 1 } } }
      where: { id: $itemID }
    ) {
      id
      votes {
        id
        voterID
        count
      }
    }
    updateToken: updateRetro(
      data: { updateToken: "0" }
      where: { id: $boardID }
    ) {
      id
    }
  }
`;

const EDIT_TEXT = gql`
  mutation EditText($boardID: ID!, $itemID: ID!, $newText: String!) {
    updateItem(data: { text: $newText }, where: { id: $itemID }) {
      id
      text
    }
    updateToken: updateRetro(
      data: { updateToken: "0" }
      where: { id: $boardID }
    ) {
      id
    }
  }
`;

const DELETE_ITEM = gql`
  mutation RemoveItem($boardID: ID!, $itemID: ID!) {
    deleteItem(where: { id: $itemID }) {
      id
    }
    updateToken: updateRetro(
      data: { updateToken: "0" }
      where: { id: $boardID }
    ) {
      id
    }
  }
`;

export function ListItem({ item, votable = true }: ListItemProps) {
  const [editMode, setEditMode] = useState(false);
  const [authorID] = useAuthorID();
  const isCurrentUser = item.authorID === authorID;

  return (
    <Card title={editMode ? "Edit" : undefined}>
      {editMode ? (
        <EditItem item={item} onClick={handleEdit} />
      ) : (
        <ShowItem
          item={item}
          votable={votable}
          onClick={handleEdit}
          isCurrentUser={isCurrentUser}
        />
      )}
    </Card>
  );

  function handleEdit() {
    setEditMode(!editMode);
  }
}

interface ShowItemProps {
  item: ListItem;
  votable: boolean;
  isCurrentUser: boolean;
  onClick(): void;
}

function ShowItem({ item, votable, isCurrentUser, onClick }: ShowItemProps) {
  const boardID = useBoardID();
  const [decryptedText] = useDecrypt([item.text]);
  const [authorID] = useAuthorID();
  const [addVote, { loading: addingVote }] = useMutation(ADD_VOTE);
  const state = useContext(StateContext);

  return (
    <>
      <Content>
        <RedactedText text={decryptedText} itemAuthor={item.authorID} />
      </Content>
      <Content>
        <div className={styles.footer}>
          {votable && (
            <div className={styles.vote}>
              <InlineLabel color="green">{countVotes(item.votes)}</InlineLabel>
              <Button
                icon="thumbsUp"
                type="tertiary"
                variation="cancel"
                ariaLabel="Vote"
                disabled={!state.showItems}
                loading={addingVote}
                onClick={handleVote}
              />
            </div>
          )}

          {isCurrentUser && (
            <div>
              <Button
                icon="edit"
                ariaLabel="Edit Card"
                type="tertiary"
                onClick={handleClick}
              />
            </div>
          )}
        </div>
      </Content>
    </>
  );

  function handleClick() {
    onClick();
  }

  function handleVote() {
    addVote({
      variables: {
        boardID,
        itemID: item.id,
        voterID: authorID,
      },
    });
  }
}

interface EditItemProps {
  item: ListItem;
  onClick(): void;
}

// eslint-disable-next-line max-statements
function EditItem({ item, onClick }: EditItemProps) {
  const boardID = useBoardID();
  const [decryptedText] = useDecrypt([item.text]);
  const [value, setValue] = useState(decryptedText);
  const [encryptedText] = useEncrypt([value.toString()]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteItem] = useMutation(DELETE_ITEM);
  const [editText, { loading: editTextLoading }] = useMutation(EDIT_TEXT);

  return (
    <>
      <Content>
        <InputText multiline value={value} onChange={handleInputChange} />
      </Content>
      <Content>
        <div className={styles.footer}>
          <div>
            <Button
              type="tertiary"
              variation="destructive"
              label="Delete"
              size="small"
              onClick={toggleDeleteModal}
            />
            <Button
              type="tertiary"
              variation="cancel"
              label="Cancel"
              size="small"
              onClick={toggleEditMode}
            />
          </div>
          <Button
            label="Save"
            size="small"
            loading={editTextLoading}
            onClick={handleSave}
          />
        </div>
      </Content>
      <ConfirmationModal
        title="Delete item"
        message={`Are you sure that you want to delete "${decryptedText}"`}
        confirmLabel="Delete Item"
        open={confirmDelete}
        onConfirm={handleDelete}
        onRequestClose={toggleDeleteModal}
      />
    </>
  );

  function handleInputChange(val: string) {
    setValue(val);
  }

  function toggleDeleteModal() {
    setConfirmDelete(!confirmDelete);
  }

  function handleDelete() {
    deleteItem({ variables: { boardID, itemID: item.id } });
  }

  function toggleEditMode() {
    onClick();
  }

  async function handleSave() {
    await editText({
      variables: {
        boardID,
        itemID: item.id,
        newText: encryptedText,
      },
    });

    toggleEditMode();
  }
}
