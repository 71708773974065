import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { InputText } from "@jobber/components/InputText";
import { Button } from "@jobber/components/Button";
import { useAuthorID, useEncrypt } from "../../../hooks";

interface CommentProps {
  columnID: string;
  loading: boolean;
  onSave(
    text: string | number,
    columnID: string,
    authorID: string | (() => void),
  ): void;
}

export function Comment({ columnID, loading, onSave }: CommentProps) {
  const [authorID] = useAuthorID();
  const [text, setText] = useState<string | number>("");
  const [encryptedText] = useEncrypt([text.toString()]);

  return (
    <Content>
      <InputText
        multiline
        placeholder="Add Card…"
        onEnter={handleSave}
        rows={{ min: 1, max: 10 }}
        value={text}
        onChange={setText}
      />
      {loading && (
        // This is really gross.
        <Button
          label="Adding"
          size="small"
          loading={true}
          fullWidth={true}
          variation="cancel"
          type="secondary"
          onClick={handleSave}
        />
      )}
    </Content>
  );

  function handleSave() {
    if (text !== "") {
      onSave && onSave(encryptedText, columnID, authorID);
      setText("");
    }
  }
}
