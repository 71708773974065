import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd"; // thanks https://codesandbox.io/s/k260nyxq9v?file=/index.js:1697-1741
import { ListItem } from "./components/ListItem";
import { StateContext } from "../../StateContext";
import { countVotes } from "../../../utilities";

interface ListItemProps {
  items: ListItem[];
  votable?: boolean;
}

export function ListItems({ items, votable = true }: ListItemProps) {
  const state = useContext(StateContext);
  const sortedItems = [...items];

  if (state.sortItems) {
    sortedItems.sort((a, b) => countVotes(b.votes) - countVotes(a.votes));
  } else {
    sortedItems.reverse();
  }

  return (
    <>
      {sortedItems.map((item: ListItem, index) => {
        return (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(snapshot, provided.draggableProps.style)}
              >
                <ListItem
                  key={item.id}
                  item={item}
                  votable={votable}
                ></ListItem>
              </div>
            )}
          </Draggable>
        );
      })}
    </>
  );
}

function getItemStyle({ isDragging, combineTargetFor }, draggableStyle) {
  let calculatedBackgroundColor;
  if (isDragging) calculatedBackgroundColor = "var(--color-yellow--lighter)";
  if (combineTargetFor) calculatedBackgroundColor = "var(--color-yellow)";

  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 4,
    margin: `0 0 2px 0`,

    // change background colour if dragging
    backgroundColor: calculatedBackgroundColor,

    // styles we need to apply on draggables
    ...draggableStyle,
  };
}
