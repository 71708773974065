import React, { useState } from "react";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { gql, useMutation } from "@apollo/client";
import { Button } from "@jobber/components/Button";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { DragDropContext, Droppable } from "react-beautiful-dnd"; // thanks https://codesandbox.io/s/k260nyxq9v?file=/index.js:1697-1741
import { decrypt, encrypt } from "../../../utilities";
import { Comment } from "../Comment";
import { ListItems } from "../ListItems";
import { ListItem } from "../ListItems/components/ListItem";
import { useAuthorID, useBoardID, useDecrypt, useSecret } from "../../../hooks";

const ADD_ITEM = gql`
  mutation AddItem($boardID: ID!, $columnID: ID!, $item: ItemCreateInput!) {
    updateRetro(
      where: { id: $boardID }
      data: {
        columns: {
          update: {
            where: { id: $columnID }
            data: { items: { create: [$item] } }
          }
        }
      }
    ) {
      id
      columns {
        id
        items {
          id
          text
          authorID
          order
        }
      }
    }
  }
`;

const DELETE_COLUMN = gql`
  mutation RemoveColumn($boardID: ID!, $columnID: ID!) {
    deleteColumn(where: { id: $columnID }) {
      id
    }
    updateToken: updateRetro(
      data: { updateToken: "0" }
      where: { id: $boardID }
    ) {
      id
    }
  }
`;

const MERGE_ITEM = gql`
  mutation MergeItem(
    $boardID: ID!
    $mergedItemID: ID!
    $combinedText: String!
    $votesToMerge: [ItemVoteCreateInput!]
    $removedItemID: ID!
  ) {
    updateItem(
      data: { text: $combinedText, votes: { create: $votesToMerge } }
      where: { id: $mergedItemID }
    ) {
      id
      text
    }
    deleteItem(where: { id: $removedItemID }) {
      id
    }
    # Hack that triggers Subscription
    updateToken: updateRetro(
      data: { updateToken: "0" }
      where: { id: $boardID }
    ) {
      id
    }
  }
`;

interface ColumnProps {
  id: string;
  title: string;
  items: ListItem[];
  color: string;
}

// eslint-disable-next-line max-statements
export function Column({
  id,
  title: encryptedTitle,
  items,
  color: encryptedColor,
}: ColumnProps) {
  const boardID = useBoardID();
  const secret = useSecret();
  const [deleteColumnModal, setDeleteColumnModal] = useState(false);
  const [addItem, { loading: addingItem }] = useMutation(ADD_ITEM);
  const [mergeItem] = useMutation(MERGE_ITEM);
  const [deleteColumn, { loading: deletePending }] = useMutation(DELETE_COLUMN);
  const [authorID] = useAuthorID();
  const [title, color] = useDecrypt([encryptedTitle, encryptedColor]);

  return (
    <Content>
      <Card title={title} accent={color || `requestColor`}>
        <Content>
          <Comment
            columnID={id}
            loading={addingItem}
            onSave={(text: string, columnID: string) =>
              handleSave(text, columnID)
            }
          />
        </Content>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" isCombineEnabled>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <Content>
                  <ListItems items={items} />
                  {provided.placeholder}
                </Content>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
      <Content>
        <Button
          variation="destructive"
          type="tertiary"
          label="Remove Column"
          icon="trash"
          loading={deletePending}
          fullWidth
          onClick={() => setDeleteColumnModal(true)}
        />
      </Content>
      <ConfirmationModal
        title="Delete column"
        message={`Are you sure that you want to delete the "${title ||
          "untitled"}" column`}
        confirmLabel="Delete Column"
        open={deleteColumnModal}
        onConfirm={handleDeleteColumn}
        onRequestClose={() => setDeleteColumnModal(false)}
      />
    </Content>
  );

  function handleDragEnd(result) {
    if (result.combine) {
      handleMergeItems(items, result);
    } else if (!result.destination) {
      return;
    } else {
      const newList = reorder(
        items,
        result.source.index,
        result.destination.index,
      );
      //call mutation to write new list
      //console.log(items, newList);
    }
  }

  function handleMergeItems(items, result) {
    const first = items.find(i => i.id === result.combine.draggableId);
    const second = items.find(i => i.id === result.draggableId);

    const combinedText = encrypt(
      [decrypt(first.text, secret), decrypt(second.text, secret)].join(
        "\n\n---\n\n",
      ),
      secret,
    );

    mergeItem({
      variables: {
        boardID,
        mergedItemID: result.combine.draggableId,
        combinedText,
        votesToMerge: second.votes.map(vote => ({
          voterID: vote.voterID,
          count: vote.count,
        })),
        removedItemID: result.draggableId,
      },
    });
  }
  // a little function to help us with reordering the result
  function reorder(list, oldIndex, newIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    console.log(result);
    return result;
  }

  function handleSave(text: string, columnID: string) {
    addItem({
      variables: {
        boardID,
        columnID,
        item: { text, authorID },
      },
    });
  }

  function getListStyle(isDraggingOver) {
    return {
      background: isDraggingOver ? "var(--color-grey--lightest)" : "",
      padding: 6,
    };
  }
  function handleDeleteColumn() {
    deleteColumn({
      variables: {
        boardID,
        columnID: id,
      },
    });
  }
}
