import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Page } from "@jobber/components/Page";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Text } from "@jobber/components/Text";
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { Card } from "@jobber/components/Card";
import { Banner } from "@jobber/components/Banner";
import { Menu } from "@jobber/components/Menu";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { AddColumnModal } from "./components/AddColumnModal";
import { Comment } from "./components/Comment";
import { Column } from "./components/Column";
import { ListItem, ListItems } from "./components/ListItems";
import styles from "./Board.css";
import { StateContext } from "./StateContext";
import { copyStringToClipboard, getFullUrl } from "../utilities";
import { useAuthorID, useDecrypt } from "../hooks";

const RETRO_FIELDS = gql`
  fragment retroFields on Retro {
    id
    title
    showItems
    sortItems
    showActionItems
    actionItems {
      id
      text
    }
    columns {
      id
      title
      color
      items {
        id
        text
        authorID
        order
        votes {
          voterID
          count
        }
      }
    }
  }
`;

const GET_BOARD = gql`
  query GetRetro($id: ID!) {
    retro(where: { id: $id }) {
      ...retroFields
    }
  }

  ${RETRO_FIELDS}
`;

const UPDATE_STATE = gql`
  mutation UpdateState($retroID: ID!, $data: RetroUpdateInput!) {
    updateRetro(data: $data, where: { id: $retroID }) {
      id
      showItems
      sortItems
      showActionItems
    }
  }
`;

const BOARD_SUBSCRIPTION = gql`
  subscription WatchRetro($id: ID!) {
    retro(where: { node: { id: $id } }) {
      node {
        id
        updateToken
        ...retroFields
      }
    }
  }

  ${RETRO_FIELDS}
`;

// eslint-disable-next-line max-statements
export function Board() {
  const [authorID] = useAuthorID();
  const { uuid: retroID, secret } = useParams<{
    uuid: string;
    secret?: string;
  }>();
  const [showCopiedBanner, setShowCopiedBanner] = useState(false);
  const [showAddColumn, setShowAddColumn] = useState(false);
  const { loading, error, data } = useQuery(GET_BOARD, {
    variables: { id: retroID },
    fetchPolicy: "cache-and-network",
  });
  const [updateState] = useMutation(UPDATE_STATE);

  useSubscription(BOARD_SUBSCRIPTION, {
    variables: { id: retroID },
  });

  if (loading) {
    return <Text>Loading</Text>;
  }

  if (error) {
    return <Text>Oops something bad happened</Text>;
  }

  const {
    columns,
    title: encryptedTitle,
    showItems,
    sortItems,
    showActionItems,
    actionItems,
  } = data.retro;

  const votesUsed = columns.reduce(
    (count, column) =>
      count +
      column.items.reduce(
        (count, item) =>
          count +
          item.votes.reduce((count, vote) => {
            if (vote.voterID === authorID) return count + vote.count;
            return count;
          }, 0),
        0,
      ),
    0,
  );

  const [title] = useDecrypt([encryptedTitle], secret);

  return (
    <StateContext.Provider
      value={{
        boardID: retroID,
        showItems,
        sortItems,
        showActionItems,
        secret,
      }}
    >
      <Helmet>
        <title>Frendly Retro: {title}</title>
      </Helmet>
      {showCopiedBanner && (
        <Banner type="success">
          The retro board URL has been copied to your clipboard.
        </Banner>
      )}
      {secret == undefined && (
        <Banner type="warning">
          Double check your URL. Missing Encryption Secret.
        </Banner>
      )}
      <Page
        title={`Retro: ${title}`}
        intro=""
        width="fill"
        primaryAction={{
          label: "Share",
          onClick: async () => {
            await copyStringToClipboard(
              getFullUrl(data.retro.id, secret),
            ).then(() => setShowCopiedBanner(true));
          },
        }}
        secondaryAction={{
          icon: "addNote",
          label: "Add Column",
          onClick: () => setShowAddColumn(true),
        }}
      >
        <Content>
          <div className={styles.actionBar}>
            <Button
              icon={showItems ? "eyeCrossed" : "eye"}
              label={`${showItems ? "Hide" : "Show"} Cards`}
              type="tertiary"
              onClick={toggleStateFor("showItems")}
            />
            <Button
              icon="sort"
              label={`Sorting by: ${sortItems ? "Votes" : "Time"}`}
              type="tertiary"
              onClick={toggleStateFor("sortItems")}
            />
            <Button
              icon={showActionItems ? "eyeCrossed" : "eye"}
              label={`${showActionItems ? "Hide" : "Show"} action items`}
              type="tertiary"
              onClick={toggleStateFor("showActionItems")}
            />
            <InlineLabel size="larger" color="lightBlue">
              {votesUsed} Votes Used
            </InlineLabel>
          </div>
          <div className={styles.boardColumns}>
            {columns.map(column => {
              return <Column key={column.id} {...column} />;
            })}
            {showActionItems && (
              <ActionItems items={actionItems} retroID={data.retro.id} />
            )}
          </div>
        </Content>
      </Page>
      <AddColumnModal
        open={showAddColumn}
        close={() => setShowAddColumn(false)}
        retroID={data.retro.id}
      />
    </StateContext.Provider>
  );

  function toggleStateFor(key: "showItems" | "sortItems" | "showActionItems") {
    return () => {
      console.log(`Toggling: ${key}`);

      const data = {
        showItems,
        sortItems,
        showActionItems,
      };
      data[key] = !data[key];

      updateState({
        variables: { retroID, data },
        optimisticResponse: {
          __typename: "Mutation",
          updateRetro: {
            __typename: "Retro",
            id: retroID,
            ...data,
          },
        },
      });
    };
  }
}

/********** ********** ********** ********** **********/

const ADD_ACTION_ITEM = gql`
  mutation AddActionItem($retroID: ID!, $data: RetroUpdateInput!) {
    updateRetro(data: $data, where: { id: $retroID }) {
      id
      actionItems {
        text
      }
    }
    updateToken: updateRetro(
      data: { updateToken: "0" }
      where: { id: $retroID }
    ) {
      id
    }
  }
`;

interface ActionItemsProps {
  retroID: string;
  items: ListItem[];
}

function ActionItems({ items, retroID }: ActionItemsProps) {
  const [addActionItem] = useMutation(ADD_ACTION_ITEM);

  return (
    <Content>
      <Card accent="green" title="Action Items">
        <Comment columnID="" onSave={(text: string) => handleSave(text)} />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" isCombineEnabled>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <Content>
                  <ListItems items={items} votable={false} />
                  {provided.placeholder}
                </Content>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
    </Content>
  );

  function handleSave(text: string) {
    addActionItem({
      variables: {
        retroID,
        data: { actionItems: { create: { text } } },
      },
    });
  }

  function handleDragEnd(result) {
    if (!result.destination) {
      return;
    }
    console.log(result);

    // reorder and set new state of list
  }

  function getListStyle(isDraggingOver) {
    return {
      background: isDraggingOver ? "lightblue" : "",
      padding: 6,
    };
  }
}
