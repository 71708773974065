import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
// eslint-disable-next-line import/no-internal-modules
import { getMainDefinition } from "@apollo/client/utilities";
import { Helmet } from "react-helmet";
// eslint-disable-next-line import/no-internal-modules
import { WebSocketLink } from "@apollo/client/link/ws";
import { Root } from "./Root";
import { Board } from "./Board";
// eslint-disable-next-line import/no-internal-modules
import favicon from "./public/assets/favicon.png";
import "./App.css";

const httpLink = new HttpLink({
  uri: "https://us1.prisma.sh/jeff-marvin/hack2020-frend-tro/dev",
});

const wsLink = new WebSocketLink({
  uri: `wss://us1.prisma.sh/jeff-marvin/hack2020-frend-tro/dev`,
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  // uri: "https://us1.prisma.sh/jeff-marvin/hack2020-frend-tro/dev",
  link: splitLink,
  cache: new InMemoryCache(),
});

export function App() {
  return (
    <ApolloProvider client={client}>
      <Helmet>
        <title>Frend-tro | The frendliest retros in the world</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@900&family=Source+Sans+Pro:wght@400;600&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Root />
          </Route>
          <Route exact path="/board/:uuid/:secret">
            <Board />
          </Route>
          <Route exact path="/board/:uuid/">
            <Board />
          </Route>
          <Route path="*">💣 404</Route>
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}
