export function useAtlantisColors() {
  return [
    {
      title: "Indigo",
      color: "rgb(85, 106, 203)",
      name: "indigo",
    },
    {
      title: "Pink",
      color: "rgb(179, 96, 150)",
      name: "pink",
    },
    {
      title: "Green",
      color: "rgb(125, 176, 14)",
      name: "green",
    },
    {
      title: "Blue",
      color: "rgb(1, 41, 57)",
      name: "blue",
    },
    {
      title: "Red",
      color: "rgb(239, 87, 51)",
      name: "red",
    },
    {
      title: "Grey",
      color: "rgb(181, 181, 181)",
      name: "grey",
    },
    {
      title: "Purple",
      color: "rgb(136, 112, 196)",
      name: "purple",
    },
    {
      title: "Orange",
      color: "rgb(243, 150, 36)",
      name: "orange",
    },
    {
      title: "Brown",
      color: "rgb(130, 107, 70)",
      name: "brown",
    },
    {
      title: "Teal",
      color: "rgb(62, 173, 146)",
      name: "teal",
    },
    {
      title: "Light Blue",
      color: "rgb(60, 162, 224)",
      name: "lightBlue",
    },
    {
      title: "Yellow",
      color: "rgb(187, 197, 32)",
      name: "yellow",
    },
  ];
}
